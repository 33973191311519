<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" /> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" /> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="heading-title1 text-left font-weight-bold"
              style="font-size: 60px !important; line-height: 90px !important; letter-spacing: 2px !important;">
              Application Services
            </h2>
          </v-col>
          <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
            <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">
              Simplifying Your Software Solutions.</h5>
          </v-col>
        </v-row>
        <div>

        </div>
      </v-container>

      <div class="photo-background">
        <span>
          <img src="../assets/images/bg/app-services.jpg" alt=""
            style="background-repeat: no-repeat; background-size: cover; background-position: bottom bottom;" />
        </span>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center">
              <h4 class="heading-title">Application Services</h4>
              <h3 class="heading-title mb-5">
                Simplifying Your Software Solutions
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">We offer a <b style="font-weight: 700;">comprehensive range</b>
                of application services to help businesses streamline their software development process, enhance their
                applications, and drive digital transformation. <b style="font-weight: 700;">With our expertise</b> in
                application development, maintenance, and support, we can assist you in harnessing the full potential of
                your software solutions.</p>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 30px;">
              <span style="font-size: 20px; font-weight: 700; color: #1f1f25;">Our Application Services</span>
            </div>
            <ServiceAppServices />
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->

    <!-- Start feature Area  -->
    <div class="rn-team-area rn-section-gapsiber bg_color--5">
      <v-container style="margin-top: 50px; margin-bottom: 50px">
        <!-- <v-row class="service-one-wrapper">
          <v-col cols="6" class="d-flex justify-end">
            <FeatureArea1 />
          </v-col>
          <v-col cols="6">
            <div class="mt-10" style="margin: auto; display: flex; width: 650px">
              <v-img contain height="auto" src="../assets/images/featured/log-monitoring.png" />
            </div>
          </v-col>
        </v-row>

        <div class="blog blog-style--LogMonitoring mt-10">
          <article class="card d-flex flex-row align-center">
            <div class="card-header d-flex flex-column align-start">
              <h4 class="h-name">
                Can Integrate to many 3rd party
              </h4>
              <p class="p-bio">
                We build and developed products to works and integrate with many 3rd party apps
              </p>
            </div>
            <figure class="card-image">
              <img src="../assets/images/featured/integratethirdparty.png" alt="Blog Images" />
            </figure>
          </article>
        </div> -->

        <!-- Start Service Area  -->
        <div class="section rn-blog-area rn-section-gap bg_color--5">

          <div class="section-title section-title--2 text-center mb--20 mb_sm--0 mb_md--0">
            <h4 class="heading-title">Benefits of Using Geopipe</h4>
            <h3 class="heading-title mb-5">
              Key benefits you can expect when using our platform:
            </h3>
            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
          </div>
          <v-row class="service-one-wrapper">
            <!-- Start Single Service  -->
            <v-col lg="6" md="6" sm="6" cols="12" v-for="(service, i) in serviceContent" :key="i">
              <div class="single-service service__style--siber" style="height: 300px !important;">
                <router-link to="/service-details">
                  <div class="service">
                    <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                    <v-icon class="icon">{{ service.icon }}</v-icon>
                    <div class="content">
                      <h3 class="heading-title">{{ service.title }}</h3>
                      <p>
                        {{ service.desc }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- End Single Service  -->
      </v-container>
    </div>

    <!-- Start feature Area 2  -->
    <!-- <div class="rn-team-area rn-section-gapsiber bg_color--1">
      <v-container style="margin-top: 50px; margin-bottom: 50px">
        <v-row class="service-one-wrapper">
          <v-col cols="6">
            <div style="margin: auto; display: flex; width: 600px">
              <v-img contain height="auto" src="../assets/images/featured/simplifyinfrastructure.png" />
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-start">
            <FeatureArea2 />
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <!-- End feature Area 2  -->

    <!-- Start feature Area 3  -->
    <!-- <div class="rn-team-area rn-section-gapsiber bg_color--5">
      <v-container style="margin-top: 50px; margin-bottom: 80px">
        <v-row class="service-one-wrapper">
          <v-col cols="6" class="d-flex justify-end">
            <FeatureArea3 />
          </v-col>
          <v-col cols="6">
            <div style="margin: auto; display: flex; width: 650px">
              <v-img contain height="auto" src="../assets/images/featured/firewall.png" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <!-- End feature Area 3  -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import ServiceAppServices from "../components/service/ServiceAppServices.vue";
import FeatureArea1 from "../components/brand/BrandFeatureSecureServices1.vue";
import FeatureArea2 from "../components/brand/BrandFeatureSecureServices2.vue";
import FeatureArea3 from "../components/brand/BrandFeatureSecureServices3.vue";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";
export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    ServiceAppServices,
    FeatureArea1,
    FeatureArea2,
    FeatureArea3,
    Footer,
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    }
  },
  data() {
    return {
      serviceContent: [
        {
          icon: "mdi-lightbulb-on",
          title: "Expertise",
          desc: `Multi Years Of Experience In Implementing various industries, including GIS, banking, and digital signature solutions.`,
        },
        {
          icon: "mdi-trophy",
          title: "Top-notch Solutions",
          desc: `Our application services are customized for your specific needs, ensuring optimal and a perfect fit for your organization.`,
        },
        {
          icon: "mdi-shield-check",
          title: "Quality Assurance",
          desc: `We follow stringent quality assurance practices throughout the development lifecycle to deliver applications of the highest quality. Our rigorous testing processes ensure reliability, security, and a seamless user experience.`,
        },
        {
          icon: "mdi-emoticon",
          title: "Client Satisfaction",
          desc: `Your satisfaction is our priority. We foster open communication, provide regular updates, and actively seek your feedback throughout the development process to ensure that our services meet and exceed your expectations.`,
        },
      ],
      features: [
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/monitor.svg"),
          titleFeatures: "Map Based Flight Monitoring",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/info.svg"),
          titleFeatures: "Flight and Airport Information Panel",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/filter.svg"),
          titleFeatures: "Flight Search & Filtering",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/database.svg"),
          titleFeatures: "Real-time Data Source Processing",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/play.svg"),
          titleFeatures: "Playback Flights",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/bell.svg"),
          titleFeatures: "Custom Alert & Notification",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/pie-chart.svg"),
          titleFeatures: "Analytics & Reporting",
        },
      ]
    };
  },
};
</script>
<style scoped>
.heading-title1 {
  color: #dd8732 !important;
  line-height: 60px;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
  color: #dd8732 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.heading-caption2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.item-subtitle {
  color: #E18122 !important;
  font-family: 'Urbanist', sans-serif !important;
}
</style>