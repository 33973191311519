<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="12"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--siber" style="height: 500px !important;">
        <router-link to="/service-details">
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "map",
            title: "Geographic Information System (GIS) Solutions",
            desc:  `Our GIS Solutions enables you to visualize, analyze, and interpret spatial data effectively using mapping applications, spatial analytics tools, or location-based services that provide valuable insights to make informed decisions, optimize resource allocation, and improve operational efficiency.`,
          },
          {
            icon: "database",
            title: "Banking Data Model",
            desc: `Our banking data model services helps you design and implement comprehensive data models that facilitate efficient data management, governance, and compliance that adapts to unique data requirements, including customer data, transactions, risk management, and regulatory reporting to ensure data consistency, accuracy, integrity and empower you to gain actionable insights and make data-driven decisions.`,
          },
          {
            icon: "command",
            title: "Data Hub Centralized Electronic Signature",
            desc:  `Our electronic signature platform enables you to sign, send, and manage documents digitally to ensure the integrity and legality of digital transactions with advanced features such as authentication, audit trails, and compliance with legal and industry regulations which yielded faster turnaround times, reduced administrative overheads, and enhanced productivity.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
